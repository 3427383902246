var site = site || {};
site.Utilities = (() => {
    // Functions

    // const functionName = () => {
    //
    // };

    const underlayBelow25 = () => {
        const underlayBelowEls = document.querySelectorAll('.js-underlay-below-25');

        if(underlayBelowEls){
            underlayBelowEls.forEach((el, i) => {
                const siblingHeight = el.parentElement.nextElementSibling.getBoundingClientRect().height;
    
                el.style.paddingBottom = (siblingHeight / 4 + 96) + "px";
            })
        }
    }

    const underlayBelow50 = () => {
        const underlayBelowEls = document.querySelectorAll('.js-underlay-below-50');

        if(underlayBelowEls){
            underlayBelowEls.forEach((el, i) => {
                const sectionContainer = el.classList.contains("hero-video-header") ? el.parentElement.parentElement : el.parentElement;
                const siblingHeight = sectionContainer.nextElementSibling.getBoundingClientRect().height;
    
                el.style.paddingBottom = (siblingHeight / 2 + 96) + "px";
            })
        }
    }

    const overlayAbove25 = () => {
        const overlayAboveEls = document.querySelectorAll('.js-overlay-above-25');

        if (overlayAboveEls) {
            overlayAboveEls.forEach((el, i) => {
                const elHeight = el.getBoundingClientRect().height;
    
                el.style.marginTop = "-" + (elHeight / 4) + "px";
            })
        }
    }

    const overlayAbove50 = () => {
        const overlayAboveEls = document.querySelectorAll('.js-overlay-above-50');

        if(overlayAboveEls) {
            overlayAboveEls.forEach((el, i) => {
                const elHeight = el.getBoundingClientRect().height;
    
                el.style.marginTop = "-" + (elHeight / 2) + "px";
            })
        }
    }

    const ulCheckmarkBullets = ()  => {
        const checkmarkBullets = document.querySelectorAll('.js-checkmark-bullets');

        if(checkmarkBullets.length > 0) {
            checkmarkBullets.forEach((el, i) => {
                el.parentElement.parentElement.style.listStyle = "none";
                el.parentElement.parentElement.style.paddingLeft = 0;
            })
        }
    }

    // Return
    return {
        // functionName : functionName
        underlayBelow25 : underlayBelow25,
        underlayBelow50 : underlayBelow50,
        overlayAbove25 : overlayAbove25,
        overlayAbove50 : overlayAbove50,
        ulCheckmarkBullets : ulCheckmarkBullets
    };
})();