var site = site || {};
site.Forms = (() => {
    // Functions

    const submitForm = (event) => {
        event.preventDefault();
        
        const form = event.target;
        const formData = new FormData(form);

        const onResponse = (event) => {
            const contentType = event.target.getResponseHeader("Content-Type");

            if (contentType.indexOf("application/json") === -1) {
                const eventTargetHTML = ConvertStringToHTML(event.target.response)
                
                form.parentNode.replaceWith(...eventTargetHTML.childNodes);
                replaceFormOnSubmit();
            } else {
                const json = JSON.parse(event.target.response);

                location.href = json.redirectTo;
            }
        };

        const reCaptchaV3FieldName = form.id + '.ReCaptchaV3FormComponent.Value';
        
        if (formData.has(reCaptchaV3FieldName) && typeof grecaptcha === 'object') {
            const reCaptchaSiteKey = window.reCaptchaSiteKey;
            
            grecaptcha.execute(reCaptchaSiteKey, { action: 'submit' }).then((token) => {
                formData.set(reCaptchaV3FieldName, token);
                createRequest(form, formData, onResponse);
            });
        } else {
            createRequest(form, formData, onResponse);
        }
    }

    const createRequest = (form, formData, onResponse) => {
        var xhr = new XMLHttpRequest();

        xhr.addEventListener("load", onResponse);

        xhr.open("POST", form.action);
        xhr.send(formData);
    }

    const replaceFormOnSubmit = () => {
        const reCaptchaV3Elements = document.querySelectorAll('[data-recaptchav3]');

        reCaptchaV3Elements.forEach(element => {
            const parentForm = element.closest('form');
            
            parentForm.setAttribute('onsubmit', 'site.Forms.submitForm(event)');
            floatLabel();
        });
    }

    const observeForms = () => {
        // Select the node that will be observed for mutations
        const targetNode = document;

        // Callback function to execute when mutations are observed
        const callback = (mutationList, observer) => {
            mutationList.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    if(mutation.addedNodes[1] != undefined && mutation.addedNodes[1].classList != undefined) {
                        if (mutation.addedNodes[1].classList.contains('form')) {
                            replaceFormOnSubmit();
                        }
                    }
                }
            })
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, {childList: true, subtree: true});
    }

    const floatLabel = () => {
        const formLabels = document.querySelectorAll('.control-label');

        formLabels.forEach((el, i) => {
            const formField = el.nextElementSibling;

            if(formField.classList.contains("form-control")){
                const clone = el.cloneNode(true);
                el.remove();
                formField.after(clone);
            }
        })
    }

    const ConvertStringToHTML = (str) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(str, 'text/html');

        return doc.body;
    };

    // Return
    return {
        submitForm : submitForm,
        replaceFormOnSubmit : replaceFormOnSubmit,
        observeForms : observeForms,
        floatLabel : floatLabel
    };
})();