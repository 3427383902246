var site = site || {};
site.Cookies = (function () {
    // Functions
    
    const acceptCookieConsent = () => {
        const cookie_consent = getCookie("user_cookie_consent");
    
        if (cookie_consent != "") {
            document.getElementById("cookieNotice").style.display = "none";
        } else {
            document.getElementById("cookieNotice").style.display = "block";
        }

        const button = document.querySelector('#cookieBtn');
        const buttonClose = document.querySelector('#cookieClose');
    
        // Set cookie consent
        button.addEventListener('click', () => {
            deleteCookie('user_cookie_consent');
            setCookie('user_cookie_consent', 1, 365);
            document.getElementById("cookieNotice").style.display = "none";
        })
    
        //Close window
        buttonClose.addEventListener('click', () => {
            document.getElementById("cookieNotice").style.display = "none";
        })
    }

    // Create cookie
    const setCookie = (cname, cvalue, exdays) => {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    // Delete cookie
    const deleteCookie = (cname) => {
        const d = new Date();
        d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=;" + expires + ";path=/";
    }

    // Read cookie
    const getCookie = (cname) => {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // Return
    return {
        acceptCookieConsent: acceptCookieConsent
    };
})();