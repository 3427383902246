const site = site || {};
site.Carousels = (() => {
    // Functions

    const imageGridModalCarousel = () => {
        if(document.getElementsByClassName('js-image-swiper').length){
            const imageGridCarousel = document.querySelectorAll('.js-image-swiper');

            imageGridCarousel.forEach((imageCarousel) => {
                const modal = imageCarousel.closest('.modal');

                const findImageBtn = document.querySelectorAll('.js-find-image');
                let thisImageIndex,
                    curSlide = null,
                    uto = null,
                    rto = null;

                findImageBtn.forEach(function(btn, i){
                    btn.addEventListener('click', function(){
                        console.log(modal);
                        thisImageIndex = this.dataset.imageIndex;
                    })
                });

                modal.addEventListener('shown.bs.modal', (e) => {
                    if(e.target.classList.contains("modal"))
                    {
                        //console.log('show.bs.modal.');
                        const prevBtn = imageCarousel.previousElementSibling;
                        const nextBtn = imageCarousel.nextElementSibling;

                        //console.log('thisVideoId: ', thisVideoId);

                        curSlide = thisImageIndex - 1;

                        const carousel = new Swiper(imageCarousel, {
                            init: false,//false here to be able to track the event below
                            slidesPerView: 'auto',
                            spaceBetween: 5,
                            initialSlide: curSlide,
                            autoHeight: true,
                            loop: true,//**WARNING** loop:true has a bug with initial index. it gets back on track after changing slides
                            navigation: {
                                nextEl: nextBtn,
                                prevEl: prevBtn,
                            }
                        })

                        carousel.init();
                    }
                })

                modal.addEventListener('hidden.bs.modal', () => {
                    imageCarousel.swiper.destroy();
                })
            })
        }
    }

    const logoSlider = (mediaQueries) => {
        if(document.getElementsByClassName('js-logo-slider-content').length) {
            const logoSliders = document.querySelectorAll('.js-logo-slider-content');

            logoSliders.forEach((logoSlider) => {
                const logoSliderContainer = logoSlider.parentNode;
                const logoSliderThumbs = logoSliderContainer.querySelector('.js-logo-slider-thumbs');
                const logoDropdown = logoSliderContainer.querySelector('.js-logo-dropdown');

                if(mediaQueries.lg.matches) {
                    const prevBtn = logoSliderThumbs.previousElementSibling;
                    const nextBtn = logoSliderThumbs.nextElementSibling;

                    const swiper = new Swiper(logoSliderThumbs, {
                        slidesPerView: 12,
                        watchSlidesProgress: true,
                        grid: {
                            rows: 2,
                            fill: "row",
                        },
                    });

                    const swiper2 = new Swiper(logoSlider, {
                        spaceBetween: 20,
                        navigation: {
                            nextEl: nextBtn,
                            prevEl: prevBtn,
                        },
                        thumbs: {
                            swiper: swiper,
                        },
                    });
                    swiper.on('click', function () {
                        const swiperClickedIndex = swiper.clickedIndex;
                        swiper.slideTo(swiperClickedIndex, 500);
                    });

                    swiper2.on('slideChange', function(){
                        const swiperIndex = swiper2.activeIndex;
                        swiper.slideTo(swiperIndex, 500);

                        const logoSliderPlayers = logoSlider.querySelectorAll('.js-yt-video');

                        logoSliderPlayers.forEach((imageSliderPlayer) => {
                            const videoContainer = imageSliderPlayer.parentElement.parentElement;
                            const videoTrigger = videoContainer.previousElementSibling;

                            videoContainer.classList.remove('z-index-4');
                            videoTrigger.classList.remove('is-hidden');
                        })

                        const ytPlayers = logoSlider.querySelectorAll('.js-yt-video');

                        ytPlayers.forEach((ytPlayer) => {
                            const iframe = ytPlayer.contentWindow;
                            iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                        })
                    });
                } else {
                    const swiper2 = new Swiper(logoSlider, {
                        spaceBetween: 20,
                    });

                    logoDropdown.addEventListener('change', function () {
                        const logoIndex = logoDropdown.selectedIndex;
                        swiper2.slideTo(logoIndex, 500);
                    });

                    swiper2.on('slideChange', function(){
                        const swiperIndex = swiper2.activeIndex;
                        logoDropdown.selectedIndex = swiperIndex;

                        const logoSliderPlayers = logoSlider.querySelectorAll('.js-yt-video');

                        logoSliderPlayers.forEach((imageSliderPlayer) => {
                            const videoContainer = imageSliderPlayer.parentElement.parentElement;
                            const videoTrigger = videoContainer.previousElementSibling;

                            videoContainer.classList.remove('z-index-4');
                            videoTrigger.classList.remove('is-hidden');
                        })

                        const ytPlayers = logoSlider.querySelectorAll('.js-yt-video');

                        ytPlayers.forEach((ytPlayer) => {
                            const iframe = ytPlayer.contentWindow;
                            iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                        })
                    })
                }
            })
        }
    }

    const copyWithSlider = () => {
        if(document.getElementsByClassName('js-copy-with-slider').length) {
            const sliders = document.querySelectorAll('.js-copy-with-slider');

            sliders.forEach((slider) => {
                const prevBtn = slider.previousElementSibling;
                const nextBtn = slider.nextElementSibling;
                var swiper = new Swiper(slider, {
                    centeredSlides: true,
                    navigation: {
                        nextEl: nextBtn,
                        prevEl: prevBtn,
                    },
                });
            })
            
        }
    }    

    const navTabsSlider = () => {
        if(document.getElementsByClassName('js-nav-slider').length) {
            const sliders = document.querySelectorAll('.js-nav-slider');

            sliders.forEach((slider) => {
                const prevBtn = slider.previousElementSibling;
                const nextBtn = slider.nextElementSibling;
                var swiper = new Swiper(slider, {
                    slidesPerView: 2.5,
                    navigation: {
                        nextEl: nextBtn,
                        prevEl: prevBtn,
                    },
                });
            })
        }        
    }


    // Return
    return {
        imageGridModalCarousel : imageGridModalCarousel,
        copyWithSlider : copyWithSlider,
        logoSlider : logoSlider,
        navTabsSlider: navTabsSlider,
    };
})();