var site = site || {};
site.VirtualTour = (() => {
    // Functions
    
    const imageMap = () => {
        var imageMaps = document.querySelectorAll('.js-virtual-tour');

        if(imageMaps.length > 0) {
            var scriptIsLoaded = document.querySelectorAll("script[src='https://www.youtube.com/iframe_api']").length > 0;
                        
            if (!scriptIsLoaded){
                // Initialize YouTube's iframe_api script on first load
                var tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';
                tag.id = 'apiScript';
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }

            imageMaps.forEach((imageMap) => {
                const videoModal = imageMap.querySelector(".js-video-modal");
                const bsModal = new bootstrap.Modal(videoModal);
    
                const videoPlaylistWrapper = imageMap.querySelector(".js-video-playlist-wrapper");
                const videoPlaylist = imageMap.querySelector(".js-video-playlist");
    
                const videoEmbed = imageMap.querySelector(".js-video-embed");
    
                const imageMapAreas = imageMap.querySelectorAll('area');
    
                // Parse <area> markup and create playlist items in video gallery
                imageMapAreas.forEach((imageMapArea) => {
                    const youtubeUrl = imageMapArea.getAttribute('href');
                    const areaVideoTitle = imageMapArea.getAttribute('title');
                    
                    imageMapArea.addEventListener('click', (e) => {
                        e.preventDefault();
        
                        // If URL is for a playlist
                        if (youtubeUrl.indexOf('playlist') != -1) {
                            // Clear any existing playlist markup
                            videoPlaylist.innerHTML = "";
    
                            // Get Playlist ID from URL
                            var playlistUrl = youtubeUrl.split('list=');
                            const playlistId = playlistUrl[1];
                            
                            // Create Playlist
                            parsePlaylist(videoEmbed, videoPlaylist, playlistId, bsModal);
                        }
                        else if (youtubeUrl.indexOf('v=') != -1)  { // If URL is for a single video
                            // Clear any existing playlist markup
                            videoPlaylist.innerHTML = "";
                            // Hide the playlist
                            videoPlaylistWrapper.classList.add('d-none');
    
                            // Get Video ID from URL
                            var videoUrl = youtubeUrl.split('v=');
                            const videoId = videoUrl[1];
    
                            videoEmbed.setAttribute("src", "https://youtube.com/embed/" + videoId + "?enablejsapi=1");
                            videoEmbed.setAttribute("title", areaVideoTitle);
                        }                    
                    })
                })
    
                // CLOSE MODAL
                videoModal.addEventListener('hidden.bs.modal', () => {
                    videoPlaylistWrapper.classList.remove('d-none');
                    videoEmbed.innerHTML = '<div id="video-embed"></div>';
                })
            })
        }
    }

    const parsePlaylist = (videoEmbed, videoPlaylist, id, modal) => {
        fetch("https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&key=AIzaSyA6l4KeFMvw5eU2sXVtFB22-RBFQEidL-8&playlistId="+id+"&maxResults=100")
        .then((data) => {
            return data.json();
        })
        .then(data => {
            // Create Playlist Items
            const playlistItems = data.items;
            
            playlistItems.forEach((item, i) => {
                var videoId = item.snippet.resourceId.videoId;
                var videoTitle = item.snippet.title;
                var videoThumbnailUrl = item.snippet.thumbnails.default.url;

                // Add item to playlist as `selected` item
                var li = document.createElement('li');
                li.className = "js-video-item video-playlist-item list-group-item mb-0 c-pointer";
                li.setAttribute("data-video-id", videoId);
                li.setAttribute("role", "button");
                li.setAttribute("tabindex", "0");
                li.innerHTML = `
                    <div class="row g-0 pe-none">
                        <div class="col-4">
                            <img class="js-video-thumb w-100" src="${videoThumbnailUrl}" alt="${videoTitle}">
                        </div>
                        <div class="col-8 d-flex align-items-center">
                            <p class="js-video-description mb-0 ps-3 body-sm">${videoTitle}</p>
                        </div>
                    </div>
                `;

                if (i === 0) {
                    updatePlayer(videoId);
                    
                    li.classList.add("selected");                    
                }

                videoPlaylist.append(li);

                videoPlaylist.addEventListener('click', function(e) {
                    console.log(e.target);
                    if(e.target.classList.contains('js-video-item') && !e.target.classList.contains('selected')) {
                        console.log('Hello');
                        playlistClick(e.target);
                    }
                })

                // Open Modal
                modal.show();
            });

            const playlistClick = (playlistVideo) => {
                var videoId = playlistVideo.getAttribute('data-video-id');
                // Reset
                videoEmbed.innerHTML = '<div id="video-embed"></div>';
                // Load selected video into player
                updatePlayer(videoId);
                // Clear current selected item
                const videoItems = videoPlaylist.querySelectorAll('.js-video-item');
                videoItems.forEach((item) => {
                    item.classList.remove('selected');
                })
                // Apply selected styling to newly selected item
                playlistVideo.classList.add('selected');
                // Scroll item into view in the playlist
                playlistVideo.scrollIntoView({
                    behavior: "smooth"
                });
            }
        });
    }

    const updatePlayer = (videoId) => {
        console.log(videoId);
    
        const player = new YT.Player('video-embed', {
            width:"560",
            height:"315",
            videoId: videoId,
            playerVars: {
                'modestbranding': 1,
                'autoplay': 1,
                'rel': 0,
                'showinfo': 0,
                'autohide': 1,
                'playsinline': 1
            },
            events: {
                'onStateChange': onModalPlayerStateChange
            }
        });
    }

    const onModalPlayerStateChange = function(event) {
        console.log('onModalPlayerStateChange: ', event.data);
        if (event.data == 0) { // 0 = ended
            const videoSelected = document.querySelector(".selected");
            videoSelected.nextElementSibling.click();
        }
    }

    // Return
    return {
        imageMap : imageMap
    };
})();

