var site = site || {};
site.Videos = (() => {
    // Functions
    let userPaused = false;
    const ytVideos = () => {
        const videos = document.querySelectorAll('.js-yt-video');

        videos.forEach(function(el){
            new YT.Player(el.id, {
                playerVars: {
                    'modestBranding': 1,
                    'autoplay': 1,
                    'rel': 0,
                    'showinfo': 0,
                    'autohide': 1
                },
                events: {
                    // call this function when player is ready to use
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
            });
        })
        
    }

    const onPlayerReady = function(event){
        const videoTrigger = event.target.g.parentElement.parentElement.previousElementSibling;
        const videoKeyboardTrigger = videoTrigger.querySelector('.js-video-kb-trigger');

        videoTrigger.addEventListener('click', function(){            
            this.classList.add('is-hidden');
            this.nextElementSibling.classList.add('z-index-4');
            event.target.playVideo();
        })

        videoKeyboardTrigger.addEventListener('keypress', function(e){
            if (e.key === 'Enter') {
                this.parentElement.classList.add('is-hidden');
                this.parentElement.nextElementSibling.classList.add('z-index-4');
                event.target.playVideo();
            }
        })
    }

    const onPlayerStateChange = function(event) {
        const videoContainer = event.target.g.parentElement.parentElement;
        const videoTrigger = videoContainer.previousElementSibling;

        if(event.data == 2) {
            userPaused = true;
            setTimeout(() => {
                if (userPaused) {
                    videoContainer.classList.remove('z-index-4');
                    videoTrigger.classList.remove('is-hidden');
                }
            }, 300);
        }
    
        if (event.data == 1 || event.data == 3) {
            videoContainer.classList.add('z-index-4');
            videoTrigger.classList.add('is-hidden');
            userPaused = false;
        }
    }

    // Return
    return {
        ytVideos : ytVideos
    };
})();
