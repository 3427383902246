var site = site || {};
site.Accordion = (() => {
    // Functions

    const openAll = () => {
        const openTrigger = document.querySelectorAll('.js-open-accordions');

        openTrigger.forEach((el, i) => {
            const accordionItem = el.parentElement.parentElement.nextElementSibling.querySelectorAll('.accordion-item');
            
            el.addEventListener('click', () => {
                accordionItem.forEach((el, i) => {
                    const collapseEl = el.querySelector('.collapse');
                    const bsCollapse = new bootstrap.Collapse(collapseEl, {toggle: false});
                    bsCollapse.show();
                })
            })
        })
    }

    const closeAll = () => {
        const closeTrigger = document.querySelectorAll('.js-close-accordions');

        closeTrigger.forEach((el, i) => {
            const accordionItem = el.parentElement.parentElement.nextElementSibling.querySelectorAll('.accordion-item');
            
            el.addEventListener('click', () => {
                accordionItem.forEach((el, i) => {
                    const collapseEl = el.querySelector('.collapse');
                    const bsCollapse = new bootstrap.Collapse(collapseEl, {toggle: false});
                    bsCollapse.hide();
                })
            })
        })
    }

    // Return
    return {
        openAll : openAll,
        closeAll : closeAll
    };
})();