var site = site || {};
site.Navigation = (() => {
    // Functions


    const dropdownOnFocus = (mediaQueries) => {
        if(mediaQueries.lg.matches){
            const dropdownTrigger = document.querySelectorAll('.js-sr-dropdown-toggle');

            dropdownTrigger.forEach((el, i) => {
                const navLink = el.parentElement.parentElement.querySelectorAll(':scope .nav-link');
                const dropdownMenu = el.parentElement.parentElement.querySelectorAll(':scope > .dropdown-menu');

                navLink[0].addEventListener('focus', () => {
                    const dropdownMenus = document.querySelectorAll('.dropdown-menu');
                    dropdownMenus.forEach((el, i) => {
                        el.classList.remove('show');
                    });
                })

                el.addEventListener('focus', () => {
                    const dropdownMenus = document.querySelectorAll('.dropdown-menu');
                    dropdownMenus.forEach((el, i) => {
                        el.classList.remove('show');
                    });
                    
                    dropdownMenu[0].classList.add('show');
                })
            })
        }
    }

    const dropdownOnClick = (mediaQueries) => {
        if(!mediaQueries.lg.matches){
            const dropdownTrigger = document.querySelectorAll('.js-mobile-dropdown-toggle');

            dropdownTrigger.forEach((el, i) => {
                const dropdownMenu = el.parentElement.parentElement.querySelectorAll(':scope > .dropdown-menu');

                el.addEventListener('click', () => {                    
                    dropdownMenu[0].classList.toggle('show');

                    const triggerIcon = el.querySelectorAll('.fa-plus');

                    console.log(triggerIcon);
                    triggerIcon[0].classList.toggle('rotate-45');
                    triggerIcon[0].classList.toggle('text-secondary');
                    triggerIcon[0].dataset.prefix = 'fal';

                    console.log(triggerIcon[0].dataset.prefix);
                })
            })
        }
    }

    // Return
    return {
        dropdownOnFocus : dropdownOnFocus,
        dropdownOnClick : dropdownOnClick
    };
})();