var site = site || {};
site.Countdown = (() => {
    // Functions

    const setTimer = () => {
        const countdownTimer = document.querySelectorAll('.js-countdown-timer');

        if(countdownTimer.length > 0)
        {
            countdownTimer.forEach((el, i) => {
                const date = el.getAttribute("data-datetime");
                const convertedDate = new Date(date).getTime();
        
                const timer = () => {
                    const now = new Date().getTime();
                    let diff = convertedDate - now;
                    if(diff < 0){
                        el.style.display = 'none';
                    } else {
                        let days = Math.floor(diff / (1000*60*60*24));
                        let hours = Math.floor(diff % (1000*60*60*24) / (1000*60*60));
                        let minutes = Math.floor(diff % (1000*60*60)/ (1000*60));
                        let seconds = Math.floor(diff % (1000*60) / 1000);
                        
                        if(days == 0) {
                            el.querySelector('.js-day-container').style.display = "none";
                            el.querySelectorAll('.js-countdown-wrapped-time')[0].style.display = "none";
                            el.querySelectorAll('.js-countdown-wrapped-time')[1].style.display = "none";

                            el.querySelector('.js-hour-container').style.fontWeight = "600";
                            el.querySelector('.js-min-container').style.fontWeight = "600";
                            el.querySelector('.js-sec-container').style.fontWeight = "600";

                        } else {
                            if(days <= 1) {
                                el.querySelector('.js-days').style.display = "none";
                            }

                            el.querySelector('.js-dayCount').innerHTML = days;
                        }

                        if(hours == 1) {
                            el.querySelector('.js-hours').style.display = "none";
                        }
                        else {
                            el.querySelector('.js-hours').style.display = "inline";
                        }

                        hours <= 9 ? hours = `0${hours}` : hours;

                        el.querySelector('.js-hourCount').innerHTML = hours;
                        
                        if(minutes == 1) {
                            el.querySelector('.js-minutes').style.display = "none";
                        }
                        else {
                            el.querySelector('.js-minutes').style.display = "inline";
                        }

                        minutes <= 9 ? minutes = `0${minutes}` : minutes;

                        el.querySelector('.js-minCount').innerHTML = minutes;

                        if(seconds == 1) {
                            el.querySelector('.js-seconds').style.display = "none";
                        }
                        else {
                            el.querySelector('.js-seconds').style.display = "inline";
                        }

                        seconds <= 9 ? seconds = `0${seconds}` : seconds;

                        el.querySelector('.js-secCount').innerHTML = seconds;
                    }
                }

                timer();
                setInterval(timer,1000);
            })
        }
    }

    // Return
    return {
        setTimer : setTimer
    };
})();