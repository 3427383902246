const site = site || {};
site.Tabs = (() => {

    const tabsJump = () => {
        if(document.getElementsByClassName('js-tabs').length){
            function activateTab(tabId) {
                const tabButtons = document.querySelectorAll(`.js-tabs-btn[data-bs-target="${tabId}"]`);

                if (tabButtons.length) {
                    tabButtons.forEach(tabButton => {
                        const tab = new bootstrap.Tab(tabButton);
                        tab.show();
                    });
                }
            }

            const handleScrollOnLoad = (tabId) => {
                const targetElement = document.querySelector(tabId);
                if (targetElement) {
                    setTimeout(() => {
                        window.scrollTo({
                            top: targetElement.offsetTop - 150,
                            behavior: 'smooth'
                        });
                    }, 100);
                }
            };

            const handleQueryStringOnLoad = () => {
                const urlParams = new URLSearchParams(window.location.search);
                const tabId = urlParams.get('tab');
                if (tabId) {
                    const tabSelector = `#${tabId}`;
                    activateTab(tabSelector);
                    handleScrollOnLoad(tabSelector);
                }
            };

            handleQueryStringOnLoad();

            const tabsBtns = document.querySelectorAll('.js-tabs-btn');
            tabsBtns.forEach(tabsBtn => {
                tabsBtn.addEventListener('click', (event) => {
                    event.preventDefault();
                    const targetId = tabsBtn.getAttribute('data-bs-target').substring(1);
                    const urlParams = new URLSearchParams(window.location.search);
                    urlParams.set('tab', targetId);

                    history.pushState(null, null, `?${urlParams.toString()}`);
                    activateTab(`#${targetId}`);
                });
            });
        }
    }

    // Return
    return {
        tabsJump: tabsJump,
    };
})();