document.addEventListener('DOMContentLoaded', function () {
    // Bootstrap matching Media Queries
    //To use:
    //      Pass mediaQueries into module function: extranet.Utilities.function(mediaQueries)
    //      In module function:
    //          if(mediaQueries.sm.matches){
    //              code here
    //          }
    var mediaQueries = {
        sm: window.matchMedia('(min-width: 576px)'),
        md: window.matchMedia('(min-width: 768px)'),
        lg: window.matchMedia('(min-width: 992px)'),
        xl: window.matchMedia('(min-width: 1200px)')
    }

    //------------------------------------------------------------------------//
    //  Initialize Lazyloading with support for native lazyload
    //
    //  Defaults to use lazyload plugin, but will fall back to native support 
    //  for browsers with support for the loading="lazy" attribute
    //  (https://caniuse.com/#feat=loading-lazy-attr)
    //------------------------------------------------------------------------//
    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
        use_native: true 
    });
    //------------------------------------------------------------------------//
    //  PUBLIC MODULE FUNCTIONS
    //------------------------------------------------------------------------//
    //  Usage Examples: 
    //      site.Utilities.functionName()
    //      site.Utilities.functionName(mediaQueries)
    //------------------------------------------------------------------------//
    site.VirtualTour.imageMap();
    site.Utilities.underlayBelow25();
    site.Utilities.underlayBelow50();
    site.Utilities.overlayAbove25();
    site.Utilities.overlayAbove50();
    site.Utilities.ulCheckmarkBullets();
    site.Navigation.dropdownOnFocus(mediaQueries);
    site.Navigation.dropdownOnClick(mediaQueries);
    site.Accordion.openAll();
    site.Accordion.closeAll();
    site.Search.toggleSearchBox();
    site.Search.searchResults();
    site.Forms.replaceFormOnSubmit();
    site.Forms.observeForms();
    site.Forms.floatLabel();
    site.Carousels.imageGridModalCarousel();
    site.Carousels.copyWithSlider();
    site.Carousels.logoSlider(mediaQueries);
    site.Carousels.navTabsSlider();
    site.Cookies.acceptCookieConsent();
    site.Countdown.setTimer();
    site.Tabs.tabsJump();


    $('img[usemap]').rwdImageMaps(); // jQuery because vanilla doesn't seem to work / Uses Kentico's built-in version
    
    // Check DOM for images loaded via dynamically and update Lazyload instance
    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }
    
    //------------------------------------------------------------------------//
    // WINDOW RESIZE FUNCTIONS
    //------------------------------------------------------------------------//
    window.addEventListener('resize', debounce(function() {
        //------------------------------------------------------------------------//
        //  Public module functions
        //------------------------------------------------------------------------//
        //  Usage Examples: 
        //      site.Utilities.functionName()
        //      site.Utilities.functionName(mediaQueries)
        //------------------------------------------------------------------------//
        site.Utilities.underlayBelow25();
        site.Utilities.underlayBelow50();
        site.Utilities.overlayAbove25();
        site.Utilities.overlayAbove50();
        site.Navigation.dropdownOnFocus(mediaQueries);
        site.Navigation.dropdownOnClick(mediaQueries);
        site.Carousels.logoSlider(mediaQueries);
        site.Carousels.navTabsSlider(mediaQueries);
    }, 200));
}, false);

var scriptIsLoaded = document.querySelectorAll("script[src='https://www.youtube.com/iframe_api']").length > 0;
    
if (!scriptIsLoaded){
    // Inject YouTube API script
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function onYouTubeIframeAPIReady() {
    site.Videos.ytVideos();
}