var site = site || {};
site.Search = (() => {
    // Functions

    const toggleSearchBox = () => {
        const toggleBtn = document.querySelectorAll('.js-toggle-search');

        toggleBtn.forEach((el, i) => {
            const searchBox = document.getElementById('search-box');
            
            el.addEventListener('click', () => {
                const faIcon = el.querySelector('svg');

                faIcon.classList.toggle('fa-magnifying-glass');
                faIcon.classList.toggle('fa-xmark');
                faIcon.classList.toggle('fa-lg');

                if(el.getAttribute('aria-label') == "Open Searchbox") {
                    el.setAttribute('aria-label', 'Close Searchbox');
                } else {
                    el.setAttribute('aria-label', 'Open Searchbox');
                }
                
                searchBox.classList.toggle('open');
            })
        })
    }

    const searchResults = () => {
        const openTrigger = document.querySelectorAll('.js-submit-search');
 
        openTrigger.forEach((el, i) => {            
            el.addEventListener('click', () => {
                const prevSibling = el.previousElementSibling;
                const searchBox = prevSibling.querySelector('.js-keyword');
                var keyword = searchBox.value;
                var url = "/search?keyword=" + keyword;

                location.href = url;
            })
        });

        const keywordInputField = document.querySelectorAll('.js-keyword');

        keywordInputField.forEach((el, i) => {            
            el.addEventListener('keyup', (e) => {
                if(e.keyCode == 13) {
                    var keyword = el.value;
                    var url = "/search?keyword=" + keyword;
    
                    location.href = url;
                }
            })
        });


    }

    // Return
    return {
        toggleSearchBox : toggleSearchBox,
        searchResults : searchResults
    };
})();